import React, { useEffect, useState } from "react";
import axios from "axios";
import './Css/DataEvaluation.css';
import { FcApproval } from "react-icons/fc";

function DataEvaluations({ searchTerm, onDataLoaded }) {
    const [evaluations, setEvaluations] = useState([]);
    const [sortedEvaluations, setSortedEvaluations] = useState([]); // Estado para los datos ordenados
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null }); // Configuración de orden

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.post(
                    `${process.env.REACT_APP_URL_BASE}/areas-evaluations/get-evaluations`,
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        }
                    }
                );

                if (response.data.status) {
                    setEvaluations(response.data.data);
                    setSortedEvaluations(response.data.data); // También inicializa los datos ordenados
                    if (onDataLoaded) {
                        onDataLoaded(response.data.data);
                    }
                } else {
                    console.error("Error en la respuesta: status false");
                }
            } catch (error) {
                console.log("Ocurrió un problema al obtener los datos");
                console.error(error);
            }
        };
        fetchData();
    }, []);

    // Filtrar los datos según el término de búsqueda
    const filteredEvaluations = sortedEvaluations.filter((item) =>
        (item.area && item.area.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.atributo && item.atributo.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.descripcion && item.descripcion.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // Función para alternar el orden
    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
            direction = null;
        }

        setSortConfig({ key, direction });

        if (direction) {
            const sorted = [...filteredEvaluations].sort((a, b) => {
                if (key === 'fecha') {
                    // Ordena por fecha (de más reciente a más antigua o viceversa)
                    const dateA = new Date(a[key]);
                    const dateB = new Date(b[key]);
                    return direction === 'asc' ? dateA - dateB : dateB - dateA;
                } else if (key === 'calificacion') {
                    // Ordena por calificación numérica
                    return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
                } else {
                    // Ordena alfabéticamente para otras columnas
                    if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
                    if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
                    return 0;
                }
            });
            setSortedEvaluations(sorted);
        } else {
            setSortedEvaluations(evaluations); // Restaura el orden original
        }
    };

    return (
        <div className="hscroll">
            {filteredEvaluations.length > 0 ? (
                <table className="evaluation-table">
                    <thead>
                    <tr>
                        <th onClick={() => handleSort('idEvaluation')}>Id</th>
                        <th onClick={() => handleSort('area')}>Área</th>
                        <th onClick={() => handleSort('atributo')}>Propiedad</th>
                        <th onClick={() => handleSort('calificacion')}><FcApproval /></th>
                        <th onClick={() => handleSort('descripcion')}>Tipo</th>
                        <th onClick={() => handleSort('fecha')}>Fecha</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredEvaluations.map((item) => (
                        <tr key={item.idEvaluation}>
                            <td>{item.idEvaluation}</td>
                            <td>{item.area}</td>
                            <td>{item.atributo}</td>
                            <td>{item.calificacion}</td>
                            <td>{item.descripcion}</td>
                            <td>{item.fecha}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            ) : (
                <p>No hay datos disponibles</p>
            )}
        </div>
    );
}

export default DataEvaluations;
