import axios from 'axios';
import './Css/Ratings.css';
import Swal from 'sweetalert2';
import LOGO from './img/logo_fp.png';
import HomeButton from './HomeButton';
import {useNavigate, useParams} from 'react-router-dom';
import React, {useState} from 'react';
import { FaSmile, FaMeh, FaFrown, FaGrinBeam, FaAngry } from 'react-icons/fa';
import { useAreas } from './AreasContext'
import RatingComedor from "./RatingComedor";

const Ratings = () => {
    useNavigate();
    const { areaId } = useParams();
    const areas = useAreas();
    const [ratings, setRatings] = useState({
        servicio: 0,
        comida: 0,
        limpieza: 0,
    });
  const name = "Anonimo";

  // Función para manejar el clic en los emojis de cada categoría
  const handleEmojiClick = async (category, selectedRating) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [category]: selectedRating,
    }));

    const data = {
        nameuser: name,
        idArea: areaId,
        idEvaluationType: getEvaluationTypeId(category), // Asigna el tipo de evaluación 
        idRating: selectedRating, // Asigna la calificación seleccionada (emoji)
        feedback: "Sin comentarios"
    };

    try {
      await axios.post(`${process.env.REACT_APP_URL_BASE}/areas-evaluations/send-evaluation`, data);
      await Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: `Evaluación de ${category} enviada correctamente.`,
      });

      setRatings(prevRatings => ({
        ...prevRatings,
        [category]: 0, // Reinicia el rating para esa categoría después de enviar la evaluación
      }));
    } catch (error) {
      console.error('Error al enviar la evaluación:', error);
      await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Ocurrió un error al enviar la evaluación de ${category}.`,
      });
    }
  };

  // Asigna el ID del tipo de evaluación según la categoría
  const getEvaluationTypeId = (category) => {
    const types = {
      servicio: 1,
      comida: 2,
      limpieza: 3,
    };
    return types[category];
  };

  const renderEmojiSet = (category) => (
      <div className="emoji-container">
        <h3>Evalúa {category.charAt(0).toUpperCase() + category.slice(1)}</h3>
        <FaGrinBeam
            className={`emoji ${ratings[category] === 5 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 5)}
        />
        <FaSmile
            className={`emoji ${ratings[category] === 4 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 4)}
        />
        <FaMeh
            className={`emoji ${ratings[category] === 3 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 3)}
        />
        <FaFrown
            className={`emoji ${ratings[category] === 2 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 2)}
        />
        <FaAngry
            className={`emoji ${ratings[category] === 1 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 1)}
        />
      </div>
  );

    const areaSeleccionada = Array.isArray(areas) ? areas.find(area => area.idArea === areaId) : null;
    return (
        <div className="container">
            <img src={LOGO} alt="Logo de la empresa" className="logo"/>
            <h1>Evaluación de {areaSeleccionada ? areaSeleccionada.name : 'Área No Disponible'}</h1>

            {areaSeleccionada ? (
                areaSeleccionada.idArea === 'CMD' ? (
                    <>
                        <RatingComedor />
                    </>
                ) : areaSeleccionada.idArea.startsWith('WC') ? (
                    <>
                        {renderEmojiSet('limpieza')}
                    </>
                ) : (
                    renderEmojiSet('servicio')
                )
            ) : (
                <p>Cargando áreas...</p>
            )}
            <HomeButton/>
        </div>
    );
};

export default Ratings;
