import { useState } from 'react';
import HomeButton from './HomeButton';
import LOGO from './img/logo_fp.png';
import './Css/Login.css';
import { IoIosLogIn } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        const user = $('#username').val();
        const pass = $('#password').val();

        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_BASE}/auth/login`, {
                username: user,
                password: pass,
            });

            const { token, username: userResponse, role } = response.data;
            localStorage.setItem('authToken', token);
            localStorage.setItem('username', userResponse);
            localStorage.setItem('role', role);

            Swal.fire('Autenticación correcta', `Hola ${userResponse}`, 'success');
            navigate(`/AdminView`);
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            Swal.fire('Error', 'Usuario o contraseña incorrectos', 'error');
        }
    };

    return (
        <div className="container">
            <img src={LOGO} alt="Logo de la empresa" className="logo" />

            <form className="frmLogin" onSubmit={handleLogin}>
                <p className="heading">Inicia Sesión </p>
                <div className="inputContainer">
                    <div className="inputIcon"><FaUser /></div>
                    <input
                        placeholder="Username"
                        id="username"
                        className="inputField"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className="inputContainer">
                    <div className="inputIcon"><RiLockPasswordFill /></div>
                    <input
                        placeholder="Password"
                        id="password"
                        className="inputField"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" id="btnLogin"><IoIosLogIn /> Ingresar</button>
            </form>

            <HomeButton />
        </div>
    );
}

export default Login;
