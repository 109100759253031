import LOGO from "./img/logo_fp.png";
import React from "react";
import './Css/RestroomRating.css'
import { BiPackage } from "react-icons/bi";
import HomeButton from "./HomeButton";
import {useNavigate} from "react-router-dom";
import { useAreas } from './AreasContext';
import { MdOutlineSupportAgent, MdDining, MdAdminPanelSettings, MdForklift } from "react-icons/md";

function RestroomRating() {
    const navigate = useNavigate();
    const areas = useAreas();
    if (!Array.isArray(areas)) return <p>Cargando áreas...</p>;

    const sanitarios = areas.filter(area => area.idArea.includes('WC'));
    return (
        <div>
            <div className='container'>
                <img src={LOGO} alt="Logo de la empresa" className="logo"/>
                <h1>Selecciona el Sanitario a Evaluar</h1>

                <div className='button-container'>
                    <button
                        key={sanitarios[0].idArea.toString()}
                        onClick={() => navigate(`/ratings/${sanitarios[0].idArea}`)}
                        className="btn btnAdmon">
                        {sanitarios[0].name} <MdAdminPanelSettings/>
                    </button>
                    <button
                        key={sanitarios[1].idArea.toString()}
                        onClick={() => navigate(`/ratings/${sanitarios[1].idArea}`)}
                        className="btn btnAlmacen">
                        {sanitarios[1].name} <BiPackage/>
                    </button>
                    <button
                        key={sanitarios[2].idArea.toString()}
                        onClick={() => navigate(`/ratings/${sanitarios[2].idArea}`)}
                        className="btn btnRecepcion">
                        {sanitarios[2].name} <MdOutlineSupportAgent/>
                    </button>
                    <button
                        key={sanitarios[3].idArea.toString()}
                        onClick={() => navigate(`/ratings/${sanitarios[3].idArea}`)}
                        className="btn btnComedor">
                        {sanitarios[3].name} <MdForklift/></button>
                    <button
                        key={sanitarios[4].idArea.toString()}
                        onClick={() => navigate(`/ratings/${sanitarios[4].idArea}`)}
                        className="btn btnDireccion">
                        {sanitarios[4].name} <MdDining /></button>
                </div>
                <HomeButton/>
            </div>
        </div>
    )
}

export default RestroomRating;