// src/components/Navbar.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LOGO from './img/logo_fp.png';
import './Css/NavBar.css';
import { RiTableFill } from "react-icons/ri";
import { TbLogout2 } from "react-icons/tb";
import {FaUserPlus} from "react-icons/fa";

const Navbar = () => {
    const role = localStorage.getItem("role");
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        navigate('/');
    };

    return (
        <div className="navbar">
            <img src={LOGO} alt="Logo de la empresa" className="navbar-logo" />

            <button className="hamburger" onClick={toggleMenu}> ☰ </button>

            <div className={`navbar-right ${menuOpen ? 'open' : ''}`}>
                <button className="navbar-link" onClick={() => navigate("/AdminView")}><RiTableFill /> Dashboard</button>
                {role === "ADMIN" ? (
                    <button className="navbar-link" onClick={() => navigate("/UserView")}><FaUserPlus /> Usuarios</button>
                ) : null}
                <button className="navbar-link logout-button" onClick={handleLogout}><TbLogout2 /> Salir</button>
            </div>
        </div>
    );
};

export default Navbar;
