import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const AreasContext = createContext();

export const AreasProvider = ({ children }) => {
    const [areas, setAreas] = useState({});

    useEffect(() => {
        const fetchAreas = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_URL_BASE}/areas-evaluations/get-areas`);
                if (response.data.status) {
                    setAreas(response.data.data);
                }
            } catch (error) {
                console.error('Error al obtener las áreas:', error);
                await Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudieron cargar las áreas.',
                });
            }
        };

        fetchAreas();
    }, []);

    return (
        <AreasContext.Provider value={areas}>
            {children}
        </AreasContext.Provider>
    );
};

export const useAreas = () => {
    return useContext(AreasContext);
};
