// RatingComedor.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaSmile, FaMeh, FaFrown, FaGrinBeam, FaAngry } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

const RatingComedor = () => {
    const { areaId } = useParams();

    console.log(areaId);
    const [ratings, setRatings] = useState({
        servicio: 0,
        comida: 0,
        limpieza: 0,
    });
    const name = "Anonimo";

    // Asigna el ID del tipo de evaluación según la categoría
    const getEvaluationTypeId = (category) => {
        const types = {
            servicio: 1,
            comida: 2,
            limpieza: 3,
        };
        return types[category];
    };

    // Verifica si todas las evaluaciones están completadas
    const allRatingsSelected = Object.values(ratings).every((rating) => rating > 0);

    // Maneja el clic en los emojis de cada categoría con la opción de desmarcar
    const handleEmojiClick = (category, selectedRating) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [category]: prevRatings[category] === selectedRating ? 0 : selectedRating, // Desmarca si el mismo emoji es clicado
        }));
    };

    // Envío de todas las evaluaciones cuando están completas
    useEffect(() => {
        if (allRatingsSelected) {
            const id = Object.keys(ratings).map(category => ({
                areaId
            }));
            console.log(id);
            const data = Object.keys(ratings).map(category => ({
                nameuser: name,
                idArea: areaId,
                idEvaluationType: getEvaluationTypeId(category),
                idRating: ratings[category],
                feedback: "Sin comentarios"
            }));

            axios.post(`${process.env.REACT_APP_URL_BASE}/areas-evaluations/send-evaluations-cmd`, data)
                .then(() => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Éxito',
                        text: 'Evaluaciones enviadas correctamente.',
                    });
                    // Reinicia las calificaciones después de enviar
                    setRatings({
                        servicio: 0,
                        comida: 0,
                        limpieza: 0,
                    });
                })
                .catch((error) => {
                    console.error('Error al enviar las evaluaciones:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Ocurrió un error al enviar las evaluaciones.',
                    });
                });
        }
    }, [ratings, allRatingsSelected, areaId, name]);

    const renderEmojiSet = (category) => (
        <div className="emoji-container">
            <h3>Evalúa {category.charAt(0).toUpperCase() + category.slice(1)}</h3>
            <FaGrinBeam
                className={`emoji ${ratings[category] === 5 ? 'selected' : ''}`}
                onClick={() => handleEmojiClick(category, 5)}
            />
            <FaSmile
                className={`emoji ${ratings[category] === 4 ? 'selected' : ''}`}
                onClick={() => handleEmojiClick(category, 4)}
            />
            <FaMeh
                className={`emoji ${ratings[category] === 3 ? 'selected' : ''}`}
                onClick={() => handleEmojiClick(category, 3)}
            />
            <FaFrown
                className={`emoji ${ratings[category] === 2 ? 'selected' : ''}`}
                onClick={() => handleEmojiClick(category, 2)}
            />
            <FaAngry
                className={`emoji ${ratings[category] === 1 ? 'selected' : ''}`}
                onClick={() => handleEmojiClick(category, 1)}
            />
        </div>
    );

    return (
        <div>
            {renderEmojiSet('servicio')}
            {renderEmojiSet('comida')}
            {renderEmojiSet('limpieza')}
        </div>
    );
};

export default RatingComedor;
