import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const GraphicDataEvaluations = ({ dataEvaluations }) => {
    const chartRef = useRef(null); // Referencia al gráfico

    useEffect(() => {
        if (!dataEvaluations || dataEvaluations.length === 0) {
            return; // No inicializar el gráfico si no hay datos
        }

        // Crear root element
        const root = am5.Root.new(chartRef.current);

        // Configurar tema
        root.setThemes([am5themes_Animated.new(root)]);

        // Crear gráfico XY
        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true,
                layout: root.verticalLayout,
            })
        );

        // Configurar colores personalizados
        chart.set("colors", am5.ColorSet.new(root, {
            colors: [
                am5.color(0x73556e),
                am5.color(0x9fa1a6),
                am5.color(0xf2aa6b),
                am5.color(0xf28f6b),
                am5.color(0xa95a52),
                am5.color(0xe35b5d),
                am5.color(0xffa446),
            ],
        }));

        // Crear ejes
        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "area",
                renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 30 }),
            })
        );

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );

        // Crear serie
        const series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Evaluaciones",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "area",
                tooltip: am5.Tooltip.new(root, { labelText: "{categoryX}: {valueY}" }),
            })
        );

        // Configurar columnas
        series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
        });

        // Preparar datos dinámicos
        const aggregatedData = dataEvaluations.reduce((acc, evaluation) => {
            const area = evaluation.area; // Nombre del área
            const value = evaluation.calificacion; // Valor numérico de la evaluación (1-5)

            if (!acc[area]) {
                acc[area] = { area, value: 0 };
            }
            acc[area].value += value; // Sumar calificaciones por área
            return acc;
        }, {});

        const chartData = Object.values(aggregatedData);

        // Asignar datos
        xAxis.data.setAll(chartData);
        series.data.setAll(chartData);

        // Animaciones
        series.appear(1000);
        chart.appear(1000, 100);

        return () => {
            root.dispose(); // Limpiar gráfico al desmontar componente
        };
    }, [dataEvaluations]);

    return (
        <div>
            <h2>Gráfica de Evaluaciones</h2>
            {dataEvaluations && dataEvaluations.length > 0 ? (
                <div id="chartdiv" ref={chartRef} style={{ width: "100%", height: "500px" }}></div>
            ) : (
                <p>No hay datos para mostrar en la gráfica.</p>
            )}
        </div>
    );
};

export default GraphicDataEvaluations;
