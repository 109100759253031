import React, { useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import './Css/SearchInput.css';

const SearchInput = ({ onSearchChange }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleButtonClick = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    // Llama a la función de cambio de búsqueda cada vez que el usuario escribe
    const handleInputChange = (event) => {
        onSearchChange(event.target.value); // Actualiza el término de búsqueda en AdminView
    };

    return (
        <div className="search-container">
            <button className="searchBtn" onClick={handleButtonClick}>
                <IoSearch className="searchIcon" />
            </button>
            <input
                type="text"
                className={`searchInput ${isFocused ? 'expanded' : ''}`}
                placeholder="Buscar..."
                onBlur={handleBlur}
                onChange={handleInputChange} // Cambia el término de búsqueda
            />
        </div>
    );
};

export default SearchInput;
