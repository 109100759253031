import './Css/UsuariosView.css';
import Navbar from "./NavBar";
import UsersData from "./UsersData";
import React, { useState } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import { FaUsers, FaUserPlus } from "react-icons/fa";

function UsuariosView() {
    const [refresh, setRefresh] = useState(false);

    const handleRefresh = () => {
        setRefresh(prev => !prev);
    };

    const handleRegister = async () => {
        const { value: formValues } = await Swal.fire({
            title: 'Registrar usuario',
            html:`
                <style>
                    .swal2-popup .swal2-input { 
                        font-size: 1.2rem;
                        width: 80%;
                        margin-bottom: 10px;
                    }
                    .swal2-popup .form-label {
                        font-size: 14px;
                        display: inline-block;
                        margin-bottom: 5px;
                        width: 80px;
                    }
                    .swal2-popup .input-row {
                        display: flex;
                        align-items: center;
                        margin-top: 15px;
                        gap: 10px;
                    }
                </style>
                <label class="form-label">Usuario</label><input id="swal-username" class="swal2-input" type="text" placeholder="Usuario">
                <label class="form-label">Contraseña</label><input id="swal-password" class="swal2-input" type="password" placeholder="Contraseña">
                <label class="form-label">Email</label><input id="swal-email" class="swal2-input" type="email" placeholder="Correo electrónico">
                <div class="input-row">
                    <label class="form-label">Rol</label>
                    <select id="swal-role" class="swal2-input" style="width: 60%">
                        <option value="2">Usuario</option>
                        <option value="1">Admin</option>
                    </select>
                </div>
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                const username = document.getElementById('swal-username').value;
                const password = document.getElementById('swal-password').value;
                const email = document.getElementById('swal-email').value;
                const roleId = document.getElementById('swal-role').value;

                if (!username || !password || !email) {
                    Swal.showValidationMessage('Todos los campos son obligatorios');
                }
                return { username, password, email, roleId: Number(roleId) };
            }
        });

        if (formValues) {
            const { username, password, email, roleId } = formValues;

            try {
                const token = localStorage.getItem("authToken");
                await axios.post(
                    `${process.env.REACT_APP_URL_BASE}/auth/register`,
                    { username, password, email, roleId },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                await Swal.fire('Correcto', `Usuario ${username} registrado exitosamente.`, 'success');
                setRefresh(prev => !prev);

            } catch (error) {
                if (error.response && error.response.status === 409) {
                    const errorMessage = error.response.data.error;
                    const errorField = error.response.data.field;

                    if (errorField === 'email') {
                        await Swal.fire('Error', 'El correo ya está registrado. Intenta con otro.', 'error');
                    } else if (errorField === 'username') {
                        await Swal.fire('Error', 'El nombre de usuario ya está registrado. Intenta con otro.', 'error');
                    } else {
                        await Swal.fire('Error', errorMessage, 'error');
                    }
                } else {
                    console.error("Error al registrar el usuario:", error);
                    await Swal.fire('Error', 'No se pudo registrar al usuario', 'error');
                }
            }
        }
    };

    return (
        <div>
            <Navbar />
            <div className="header">
                <p className="welcome"><FaUsers className="welcomeIcon" /> Usuarios</p>
                <FaUserPlus className="addUserIcon" onClick={handleRegister} />
            </div>
            <UsersData refresh={refresh} onRefresh={handleRefresh} />
        </div>
    );
}

export default UsuariosView;
