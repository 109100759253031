import {FaAngry, FaFrown, FaGrinBeam, FaMeh, FaSmile} from "react-icons/fa";
import './Css/RatingsCount.css'
import {useEffect, useState} from "react";
import axios from "axios";

function RatingsCount({refresh}) {
    const token = localStorage.getItem('authToken');
    const [ratingsCount, setRatingsCount] = useState([]);

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const responseCount = await axios.post(
                    `${process.env.REACT_APP_URL_BASE}/areas-evaluations/get-ratings-count`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                if (responseCount.data.status) {
                    setRatingsCount(responseCount.data.data);
                }
            } catch (error) {
                console.error("Error al obtener ratings:", error);
            }
        };

        fetchCount();
    }, [refresh, token]);

    // Para revisar el contenido actualizado de ratingsCount en cada render
    useEffect(() => {
    }, [ratingsCount]);

    const totalSum = ratingsCount.reduce((accumulator, value) => accumulator + value.count, 0);

    return (
        <div>
            {ratingsCount && ratingsCount.length >= 5 && (
                <>
                    <div className="title">Calificaciones Totales</div>
                    <div className="emojis-container">
                        <div className="card">
                            <div className="icon"><FaGrinBeam className="FaGrinBeam"/></div>
                            <div className="container">
                                <h4>{ratingsCount[0].description || "Descripción no disponible"}</h4>
                                <p>Total: {ratingsCount[0].count || "0"}</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="icon"><FaSmile className="FaSmile"/></div>
                            <div className="container">
                                <h4>{ratingsCount[1].description || "Descripción no disponible"}</h4>
                                <p>Total: {ratingsCount[1].count || "0"}</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="icon"><FaMeh className="FaMeh"/></div>
                            <div className="container">
                                <h4>{ratingsCount[2].description || "Descripción no disponible"}</h4>
                                <p>Total: {ratingsCount[2].count || "0"}</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="icon"><FaFrown className="FaFrown"/></div>
                            <div className="container">
                                <h4>{ratingsCount[3].description || "Descripción no disponible"}</h4>
                                <p>Total: {ratingsCount[3].count || "0"}</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="icon"><FaAngry className="FaAngry"/></div>
                            <div className="container">
                                <h4>{ratingsCount[4].description || "Descripción no disponible"}</h4>
                                <p>Total: {ratingsCount[4].count || "0"}</p>
                            </div>
                        </div>
                    </div>
                    <div>Existen {totalSum} registros.</div>
                </>
                )}
                {(!ratingsCount || ratingsCount.length < 5) && (
                    <p>No hay datos disponibles.</p>
                )}
        </div>
    );
}

export default RatingsCount;