// src/Home.js
import React from 'react';
import LOGO from './img/logo_fp.png'
import './Css/Ratings.css'
import './Css/Home.css'
import { MdBrunchDining } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { MdOutlineLiving } from "react-icons/md";
import { GrRestroom } from "react-icons/gr";
import {useNavigate} from "react-router-dom";
import { FiLogIn } from 'react-icons/fi';

const Home = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
    };
  return (
      <div className='container'>
          <img src={LOGO} alt="Logo de la empresa" className="logo"/>
          <FiLogIn className="login-icon" onClick={handleLogin} />

          <h1>Bienvenido</h1>

          <div className='button-container'>
              <a href='/ratings/CMD'>
                  <button className="btn" id='button-Cmd'>Comedor <MdBrunchDining/></button>
              </a>
              <a href='/ratings/RCP'>
                  <button className="btn" id='button-Rcp'>Recepción <MdOutlineLiving/></button>
              </a>
              <button className="btn" id="button-Wc" onClick={() => navigate("/RestroomRating")}>Sanitarios <GrRestroom/></button>
              <a href='/ratings/CLR'>
                  <button className="btn" id='button-Clt'>Cliente recolecta <FaUserCheck/></button>
              </a>
          </div>
      </div>
  );
};

export default Home;
