import React, {useCallback, useState} from "react";
import './Css/AdminView.css';
import Navbar from './NavBar';
import { FaHome } from "react-icons/fa";
import DataEvaluations from './DataEvaluations';
import RatingsCount from './RatingsCount';
import SearchInput from './SearchInput';
import GraphicDataEvaluations from "./GraphicDataEvaluations";

const AdminView = () => {
    const username = localStorage.getItem('username');
    const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
    const [evaluationData, setEvaluationData] = useState([]);

    // Función para actualizar el término de búsqueda
    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    // Función para recibir los datos desde DataEvaluations
    const handleDataLoaded = useCallback((dataEvaluations) => {
        setEvaluationData(dataEvaluations);
    }, []);


    return (
        <div>
            <Navbar />
            <div className="AdminViewContainer">
                <div className="header">
                    <p className="welcome">
                        <FaHome className="welcomeIcon" /> {username}
                    </p>
                    <SearchInput onSearchChange={handleSearchChange} /> {/* Pasa la función */}
                </div>
                <div className="row">
                    <div className="column tableContainer">
                        <DataEvaluations searchTerm={searchTerm} onDataLoaded={handleDataLoaded} />
                    </div>
                    <div className="column ratingsCount">
                        <div className="rowCount">
                            <RatingsCount />
                        </div>
                        <div className="rowGraph">
                            <GraphicDataEvaluations dataEvaluations={evaluationData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminView;
