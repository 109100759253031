import React from 'react'
import { IoIosHome } from "react-icons/io";

const HomeButton = () => {
  return (
    <div className='container-homeButton'>
      <a href='/'>
        <button className='homeButton'><IoIosHome /></button>
      </a>
    </div>
  )
}

export default HomeButton
