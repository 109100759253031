import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Ratings from './pages/Ratings';
import AdminView from './pages/AdminView';
import { useState, useEffect } from 'react';
import UsuariosView from './pages/UsuariosView';
import RestroomRating from './pages/RestroomRating';
import RatingComedor  from "./pages/RatingComedor";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

const App = () => {
  const [role, setRole] = useState(localStorage.getItem('role'));

  useEffect(() => {
    const handleRoleChange = () => {
      setRole(localStorage.getItem('role'));
    };

    window.addEventListener("storage", handleRoleChange);
    return () => {
      window.removeEventListener("storage", handleRoleChange);
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />
    },
    {
      path: "ratings/:areaId",
      element: <Ratings />,
    },
    {
      path: "login",
      element: <Login />
    },
    {
      path: "AdminView",
      element: <AdminView />
    },
    {
      path: "UserView",
      element: role === "ADMIN" ? <UsuariosView /> : <Navigate to="/AdminView" />
    },
    {
      path: "RestroomRating",
      element: <RestroomRating />
    },
    {
      path: "/evaluar-comedor/:areaId",
      component : {RatingComedor}
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
